<template>
  <Form :heading1="'Update Account'">
    <template v-slot:details>
      <div class="md:flex md:space-x-12 block space-x-0">
        <div class="flex-1">
          <TextInput
            :label="'First name'"
            :defaultValue="user.firstName"
            @input="setSurname"
            :types="'text'"
          />
          <TextInput
            :label="'Other name(s)'"
            :defaultValue="user.lastName"
            @input="setOthername"
            :types="'text'"
          />
          <TextInput
            :label="'Gender'"
            @input="setGender"
            :defaultValue="user.gender"
            :types="'select'"
            :options="[
              { id: 'MALE', name: 'MALE' },
              { id: 'FEMALE', name: 'FEMALE' },
            ]"
          />
          <TextInput
            :label="'Email'"
            @input="setEmail"
            :defaultValue="user.email"
            :types="'text'"
          />
        </div>

        <div class="flex-1">
          <TextInput
            :label="'National ID/ Passport'"
            :defaultValue="user.nationalId"
            @input="setNationalID"
            :types="'text'"
          />
          <TextInput
            :label="'Phone number'"
            :defaultValue="user.mobile"
            @input="setPhoneNumber"
            :types="'text'"
          />
          <TextInput
            v-if="!isMe"
            :label="'Status'"
            @input="setStatus"
            :defaultValue="user.status"
            :types="'select'"
            :options="[
              { id: 'ACTIVE', name: 'ACTIVE' },
              { id: 'INACTIVE', name: 'INACTIVE' },
              { id: 'PENDING', name: 'PENDING' },
              { id: 'RESET', name: 'RESET' },
            ]"
          />
          <a @click="$router.push(`/update-account/${$route.params.id}/user-credential`)">Change password</a>
        </div>
      </div>

      <Button :first-l="'Back'" last-l="Save" @action="doUpdate" />
    </template>
  </Form>
</template>

<script>
import Button from "/src/components/form/Buttons.vue";
import TextInput from "/src/components/form/TextInput.vue";
import Form from "/src/components/form/Form.vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
import Api from "@/services/Apis";

export default {
  name: "UpdateIdentification",
  components: {
    Button,
    TextInput,
    Form,
  },
  data: () => {
    return {
      user: undefined,
    };
  },
  computed: {
    ...mapGetters("auth", { me: "user" }),
    isMe() {
      return this.$route.params.id == this.me.id;
    },
  },
  beforeMount() {
    if (this.isMe) this.user = this.me;
    else if (this.me.roles[0].name !== "ADMIN") {
      this.$router.push(`/update-account/${this.me.id}/user-identify`);
      this.user = this.me;
    } else this.getUser();
  },
  methods: {
    setStatus(value) {
      this.user.status = value;
    },
    setEmail(value) {
      this.user.email = value;
    },
    ...mapActions({ updateUser: "auth/updateUser" }),
    getUser() {
      Api.get(`users/${this.$route.params.id}`).then((response) => {
        this.user = response.data.data;
      });
    },
    setSurname(value) {
      this.user.firstName = value;
    },
    setNationalID(value) {
      this.user.nationalId = value;
    },
    setPhoneNumber(value) {
      this.user.mobile = value;
    },
    setOthername(value) {
      this.user.lastName = value;
    },
    setGender(value) {
      this.user.gender = value.target.value;
    },
    ...mapMutations("auth", { edit: "setUser" }),
    async doUpdate() {
      const { firstName, nationalId, mobile, lastName, gender, status, email } =
        this.user;

      if (
        firstName == "" ||
        nationalId == "" ||
        mobile == "" ||
        lastName == "" ||
        gender == "" ||
        status == "" ||
        email == ""
      ) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "All fields are required",
          uptime: 3000,
          hasError: true,
        });
      } else if (/^\+?\d+$/.test(mobile) == false) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "Invalid telephone number",
          uptime: 3000,
          hasError: true,
        });
      } else if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          String(email).toLowerCase()
        )
      ) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "Invalid Email",
          uptime: 3000,
          hasError: true,
        });
      } else if (mobile.length != 12) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "telephone number must be 12 digits",
          uptime: 3000,
          hasError: true,
        });
      } else if (isNaN(nationalId)) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "National ID / Passport seems to be invalid",
          uptime: 3000,
          hasError: true,
        });
        this.msg = "";
        this.submsg = "";
        this.hasError = true;
      } else {
        this.updateUser({
          user: {
            firstName,
            nationalId,
            mobile,
            lastName,
            gender,
            email
          },
          id: this.$route.params.id
        });
        if (!this.isMe)
          await Api.update("users/status", this.$route.params.id, {
            status
          });
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "Updating user ...",
          uptime: 3000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/imports/colors";
a {
  text-decoration: underline;
  cursor: pointer;
  color: $primary;
}
</style>