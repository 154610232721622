<template>
  <div>
    <div class="md:flex md:space-x-12 block space-x-0">
      <div class="flex-1">
        <TextInput
          :label="'Title'"
          :types="'text'"
          :defaultValue="document.title"
          @input="setTitle"
          :not="true"
        />
        <TextInput
          :label="'Short description'"
          @input="setDescription"
          :defaultValue="document.description"
          :types="'textarea'"
        />
        <!-- <TextInput :label="'Cluster'" :types="'text'" :not="true"/> -->
      </div>
      <div class="flex-1">
        <TextInput
          :label="'Institution'"
          :types="'select'"
          @input="setIntitution"
          :defaultValue="_document.institution.name"
          :options="institutions"
        />

        <TextInput
          :label="'Cluster'"
          @input="setCluster"
          :defaultValue="_document.cluster.name"
          :types="'select'"
          :options="clusters"
          :not="true"
        />
      </div>
    </div>
    <Button :first-l="'Cancel'" :last-l="'Save'" @action="doSave" />
  </div>
</template>

<script>
import Button from "/src/components/form/Buttons.vue";
import TextInput from "/src/components/form/TextInput.vue"
import Apis from "/src/services/Apis";
import { mapActions } from "vuex";

export default {
  name: "EditDocument",
  components: {
    Button,
    TextInput,
  },
  data: () => ({
    institutions: [],
    clusters: [],
    clicked: false,
    document: {
      description: "",
      institutionId: "",
      cluster: "",
      title: "",
    },
  }),
  props: {
    _document: Object,
  },
  methods: {
    setTitle(value) {
      this.document.title = value;
    },
    setDescription(value) {
      this.document.description = value;
    },
    setIntitution(value) {
      this.document.institutionId = value;
    },
    setCluster(value) {
      this.document.clusterId = value;
    },

    async getInstitutions() {
      await Apis.get("institutions")
        .then((res) => {
          this.institutions = res.data.content;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getClusters() {
      await Apis.get("clusters")
        .then((res) => {
          this.clusters = res.data.content;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ...mapActions({ createDocument: "document/createDocument" }),
    doSave() {
      const { description, institutionId, clusterId, title, status } =
        this.document;
      if (
        title == "" ||
        description == "" ||
        institutionId == "" ||
        clusterId == ""
      ) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "All fields are required",
          uptime: 3000,
          hasError: true,
        });
      } else {
        setTimeout(() => {
          this.clicked = false;
        }, 5000);
        if (!this.clicked) {
          this.clicked = true
          Apis.update("documents", this._document.id, {
            description,
            institutionId,
            clusterId,
            title,
            status,
          });
          this.$store.dispatch("notification/SET_NOTIFICATION", {
            msg: "Document is successfully uploaded",
            uptime: 3000,
          });
          this.$router.push("/documents");
        }

      }
    },
  },
  created() {
    this.getInstitutions();
    this.getClusters();
    this.document = {
      clusterId: this._document.cluster.id,
      description: this._document.description,
      institutionId: this._document.institution.id,
      status: this._document.status,
      title: this._document.title,
    };
  },
};
</script>

<style></style>
