import { render, staticRenderFns } from "./DecisionTaken.vue?vue&type=template&id=4ae9ab0e&v-slot%3Atab=true&"
import script from "./DecisionTaken.vue?vue&type=script&lang=js&"
export * from "./DecisionTaken.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports