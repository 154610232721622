<template>
  <Form :heading1="'Update cabinet log'" id="allTabs">
    <template v-if="document" v-slot:details>
      <ul class="my-tabs">
        <li>
          <a
            @click.prevent="setActive('general')"
            :class="{ active: isActive('general') }"
            href="#general"
            >General details</a
          >
        </li>
        <li>
          <a
            @click.prevent="setActive('decision')"
            :class="{ active: isActive('decision') }"
            href="#decision"
            >Decision taken</a
          >
        </li>
        <li v-if="$store.state.auth.user.roles[0].name !== 'GAZETTE_PROCESSOR'">
          <a
            @click.prevent="setActive('latest')"
            :class="{ active: isActive('latest') }"
            href="#latest"
            >Upload document’s latest version</a
          >
        </li>
      </ul>
      <div class="my-tab">
        <Tab :path="'general'" :isActive="activeTab === 'general'">
          <EditDocument :_document="document" />
        </Tab>
        <Tab :path="'decision'" :isActive="activeTab === 'decision'">
          <DecisionTaken v-if="$store.state.auth.user.roles[0].name !== 'GAZETTE_PROCESSOR'" :document="document" />
          <GazetteProcessorDecisionTaken v-else :document="document"/>
        </Tab>
        <Tab :path="'latest'" :isActive="activeTab === 'latest'">
          <UploadLatest />
        </Tab>
      </div>
    </template>
  </Form>
</template>

<script>
import Tab from "/src/components/Tab.vue";
import DecisionTaken from "./DecisionTaken.vue";
import EditDocument from "./EditDocument.vue";
import UploadLatest from "./UploadLatest.vue";
import Form from "/src/components/form/Form.vue";
import Api from "/src/services/Apis";
import GazetteProcessorDecisionTaken from "./GazetteProcessorDecisionTaken.vue";
export default {
  name: "AllTabs",
  components: {
    Tab,
    DecisionTaken,
    GazetteProcessorDecisionTaken,
    UploadLatest,
    EditDocument,
    Form,
  },
  data: () => ({
    activeTab: "general",
    document: undefined,
  }),
  methods: {
    isActive(tab) {
      return this.activeTab === tab;
    },
    setActive(tab) {
      this.activeTab = tab;
    },
    async getDocument() {
      const { data } = await Api.get(`documents/${this.$route.params.id}`);
      this.document = data;
    },
  },
  async created() {
    await this.getDocument();
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/imports/colors.scss";
#allTabs {
  ul.my-tabs {
    display: block;
    list-style: none;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #b7bec9;
    & li {
      display: inline-block;
      padding-right: 60px;
      padding-bottom: 15px;
      cursor: pointer;
      border-bottom: 2px solid #eff2f3;
      & .active {
        padding-bottom: 15px;
        color: $primary;
        border-bottom: 2px solid $primary;
      }
    }
  }
  .my-tab {
    // display: inline-block;
    color: #032b3d;
    margin: 40px 0;
    // background-color: #fff;
  }
}
</style>