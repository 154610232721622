<template>
  <Form :heading1="'Create Cluster'">
    <template v-slot:details>
      <div class="md:flex md:space-x-12 block space-x-0">
        <div class="flex-1">
          <TextInput :label="'Name'" @input="setName" :types="'text'" />
          <TextInput
            :label="'Short description'"
            @input="setDescription"
            :types="'textarea'"
          />
        </div>

        <div class="flex-1">
          <!-- <TextInput :label="'Status'" :types="'select'" @input="setStatus"  :options="[
          { id: 'ACTIVE', name: 'ACTIVE'},
          { id: 'INACTIVE', name: 'INACTIVE'}
          ]"/> -->
        </div>
      </div>

      <Button :first-l="'Cancel'" :last-l="'Save'" @action="doSave" />
    </template>
  </Form>
</template>

<script>
import Button from "/src/components/form/Buttons.vue";
import TextInput from "/src/components/form/TextInput.vue";
import Form from "/src/components/form/Form.vue";
import Apis from "/src/services/Apis";

export default {
  name: "SetUpCluster",
  components: {
    Button,
    TextInput,
    Form,
  },
  data: () => {
    return {
      cluster: {
        name: "",
        description: "",
        // status: '',
      },
      showConfirm: false,
    };
  },
  methods: {
    setName(value) {
      this.cluster.name = value;
    },
    setDescription(value) {
      this.cluster.description = value;
    },
    // setStatus(value){
    //   this.cluster.status = value.target.value
    // },
    async doSave() {
      const { description, name } = this.cluster;
      if (name == "" && description != "") {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "All fields are required",
          uptime: 3000,
          hasError: true,
        });
      } else {
        await Apis.create(`clusters`, this.cluster)
          .then(() => {
            this.$router.push('/all-clusters')
          })
          .catch((err) => console.log(err))
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "Creating cluster ...",
          uptime: 3000,
        });
      }
    },
  },
};
</script>

<style>
</style>