<template>
  <div v-show="isActive" :id="path">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Tab",
  props: {
    path: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.tab {
  display: inline-block;
  color: #032b3d;
  padding: 20px;
  background-color: #fff;
}
</style>