<template>
  <Form :heading1="'Create Account'">
    <template v-slot:details>
      <div class="md:flex md:space-x-12 block space-x-0">
        <div class="flex-1">
          <TextInput
            :defaultValue="registeredUser ? registeredUser.firstName : ''"
            :label="'First name'"
            @input="setSurname"
            :types="'text'"
          />
          <TextInput
            :label="'Other name(s)'"
            :defaultValue="registeredUser ? registeredUser.lastName : ''"
            @input="setOthername"
            :types="'text'"
          />
          <TextInput
            :label="'Gender'"
            :defaultValue="registeredUser ? registeredUser.gender : ''"
            @input="setGender"
            :types="'select'"
            :options="[
              { id: 'MALE', name: 'MALE' },
              { id: 'FEMALE', name: 'FEMALE' },
            ]"
          />
            <TextInput
            :defaultValue="registeredUser ? registeredUser.nationalId : ''"
            :label="'National ID'"
            @input="setNationalID"
            :types="'text'"
          />
        </div>



        <div class="flex-1">
        
          <TextInput
            :label="'Phone number'"
            :defaultValue="registeredUser ? registeredUser.mobile : ''"
            @input="setPhoneNumber"
            :types="'text'"
          />
          <TextInput
            :label="'User Role'"
            :defaultValue="registeredUser ? registeredUser.role : ''"
            @input="setRole"
            :types="'select'"
            :options="roles"
          />
               <TextInput
                    v-if="
            currentRoleName === 'CLUSTER_USER' ||
            currentRoleName === 'MINISTRY' 
             "
            :label="`${currentRoleName === 'CLUSTER_USER' ? 'Cluster name' : 'Ministry name'}`"
                @input="setRoleName"

                :types="'select'"
                :options="currentRoleName === 'CLUSTER_USER' ? clusters: institutions"
          />
  
        </div>
      </div>

      <Button :first-l="'Back'" :last-l="'Next'" @action="doSave" />
    </template>
  </Form>
</template>

<script>


        // <TextInput
        //     v-if="
        //     currentRole === 'MINISTRY' ||
        //     currentRole === 'CLUSTER_ADMIN'
        //     "
        //     :label="`${currentRole === 'MINISTRY' ? 'Ministry name':'Cluster name'}`"
        //     :defaultValue="currentRole === 'CLUSTER_ADMIN' ? currentRole : ''"
        //     @input="setRole"
        //     :types="'text'"
        //   />


import Button from "/src/components/form/Buttons.vue";
import TextInput from "/src/components/form/TextInput.vue";
import Form from "/src/components/form/Form.vue";
import { mapActions, mapState } from "vuex";
import Apis from "../../../services/Apis";

export default {
  name: "Identification",
  components: {
    Button,
    TextInput,
    Form,
  },
  data() {
    return {
      roles: [],
       currentRoleName:"",
      clusters: [],
      institutions: [],
      currentRole:''
    };
  },

  computed: {
    ...mapState("auth", { registeredUser: "registeredUser" }),
  },

  created() {
    this.getAllClusters();
    this.getAllInstitutions();
    this.getUserRoles();
  },

  methods: {
    async getUserRoles() {
      await Apis.get("/roles")
        .then((res) => {
    
          const userRoles = res.data.data;
          this.roles = userRoles.map((role) => {
            return {id: role.name, name: role.name };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
            async getAllClusters() {
      await Apis.get("clusters")
        .then((resp) => {
          const all = resp.data.content;
          
          this.clusters = all.map((cluster) => {
                   
            return {id: cluster.name, name: cluster.name}
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getAllInstitutions() {
      await Apis.get("institutions")
        .then((response) => {
          const all = response.data.content;
          this.institutions = all.map((institution) => {
            return {id: institution.name, name: institution.name}
          })

        })
        .catch((error) => {
          console.log(error);
        });
    },

    setSurname(value) {
      this.registeredUser.firstName = value;
    },
    setNationalID(value) {
      this.registeredUser.nationalId = value;
    },
    setPhoneNumber(value) {
      this.registeredUser.mobile = value;
    },
    setOthername(value) {
      this.registeredUser.lastName = value;
    },
    setGender(value) {
      this.registeredUser.gender = value;
    },
    setRole(value) {
      this.currentRoleName = value;
      this.registeredUser.role = value;
    },
       setRoleName(value) {
      if(this.currentRoleName === "CLUSTER_USER"){
           this.registeredUser.clusterName = value;
      }else{
              this.registeredUser.institutionName = value;
      }
    },
    ...mapActions({ signup: "auth/setUser" }),
    doSave() {
      const { firstName, nationalId, mobile, lastName, gender } =
        this.registeredUser;
      if (
        firstName == "" ||
        nationalId == "" ||
        mobile == "" ||
        lastName == "" ||
        gender == ""
      ) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "All fields are required",
          uptime: 3000,
          hasError: true,
        });
      } 
      else if (
        firstName.length < 4 || 
        lastName.length < 4
      ) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: (firstName.length < 4 ? 'firstName' : 'lastName' )+ " must have at least 4 characters",
          uptime: 3000,
          hasError: true,
        });
      }
      else if (/^\+?\d+$/.test(mobile) == false) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "Invalid telephone number",
          uptime: 3000,
          hasError: true,
        });
      } else if (mobile.length != 12) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "telephone number must be 12 digits",
          uptime: 3000,
          hasError: true,
        });
      } else if (isNaN(nationalId)) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "National ID seems to be invalid",
          uptime: 3000,
          hasError: true,
        });
      } else {
        this.signup(this.registeredUser);
        // this.$store.dispatch("notification/SET_NOTIFICATION", {
        //   msg: "Registering user ...",
        //   uptime: 3000,
        //   hasError: true,
        // });
      }
    },
  },
};
</script>

<style>
</style>