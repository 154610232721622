<template>
  <Form :heading1="'Create institution'">
    <template v-slot:details>
      <div class="md:flex md:space-x-12 block space-x-0">
        <div class="flex-1">
          <TextInput :label="'Name'" @input="setName" :types="'text'" />
          <TextInput
            :label="'Short description'"
            @input="setDescription"
            :types="'textarea'"
          />
        </div>

        <div class="flex-1">
          <TextInput
            :label="'Cluster'"
            :types="'select'"
            @input="setCluster"
            :options="clusters"
          />
          <!-- <TextInput :label="'Status'" :types="'select'" @input="setStatus" :options="[
          { id: 'ACTIVE', name: 'ACTIVE'},
          { id: 'ACTIVE', name: 'INACTIVE'}
          ]"/> -->
        </div>
      </div>

      <Button :first-l="'Cancel'" :last-l="'Save'" @action="doSave" />
    </template>
  </Form>
</template>

<script>
import Button from "/src/components/form/Buttons.vue";
import TextInput from "/src/components/form/TextInput.vue";
import Form from "/src/components/form/Form.vue";
import Apis from "/src/services/Apis";

export default {
  name: "SetupInstitution",
  components: {
    Button,
    TextInput,
    Form,
  },
  data: () => ({
    clusters: [],
    institution: {
      clusterId: "",
      description: "",
      name: "",
      // status: '',
    },
    showConfirm: false,
  }),
  methods: {
    setName(value) {
      this.institution.name = value;
    },
    setDescription(value) {
      this.institution.description = value;
    },
    // setStatus(value){
    //   this.institution.status = value.target.value
    // },
    setCluster(value) {
      this.institution.clusterId = value;
    },
    async doSave() {
      const { clusterId, description, name } = this.institution;
      if (name == "" && description != "" && clusterId != "") {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "All fields are required",
          uptime: 3000,
          hasError: true,
        });
      } else {
        await Apis.create(`institutions`, this.institution)
          .then(() => {
              this.$store.dispatch("notification/SET_NOTIFICATION", {
              msg: "Creating institution ...",
              uptime: 3000,
              })
              this.$router.push('/all-ministries')
          })
          .catch((err) => console.log(err))
        }
      },

    async getClusters() {
      await Apis.get("clusters")
        .then((res) => (this.clusters = res.data.content))
        .catch((err) => console.log(err));
    },
  },
  created() {
    this.getClusters();
  },
};
</script>

<style>
</style>