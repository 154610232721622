<template>
  <Form :heading1="'Create Account'">
    <template v-slot:details>
      <div class="md:flex md:space-x-12 block space-x-0">
        <div class="flex-1">
          <TextInput :label="'Email'" @input="setEmail" :types="'text'" />

          <TextInput
            :label="'Password'"
            @input="setPassword"
            :types="showPassword ? 'text' : 'password'"
            :hasIcon="true"
          >
            <template v-slot:pass-icon>
              <ShowPasswordIcon
                :show-password="showPassword"
                @toogle-password="showPassword = !showPassword"
              />
            </template>
          </TextInput>
        </div>

        <div class="flex-1">
          <TextInput
            @input="setConfirm"
            :types="showConfirm ? 'text' : 'password'"
            :label="'Confirm Password'"
            :hasIcon="true"
          >
            <template v-slot:pass-icon>
              <ShowPasswordIcon
                :show-password="showConfirm"
                @toogle-password="showConfirm = !showConfirm"
              />
            </template>
          </TextInput>
        </div>
      </div>

      <Button :first-l="'Back'"  :last-l="'Save'" @action="doSave" />
    </template>
  </Form>
</template>

<script>
import Button from "/src/components/form/Buttons.vue";
import TextInput from "/src/components/form/TextInput.vue";
import Form from "/src/components/form/Form.vue";
import ShowPasswordIcon from "/src/components/auth/ShowPasswordIcon.vue";
import { mapActions } from "vuex";

export default {
  name: "Credentials",
  components: {
    Button,
    TextInput,
    Form,
    ShowPasswordIcon,
  },
  data: () => {
    return {
      user: {
        email: "",
        password: "",
        backClicked : false,
      },
      showPassword: false,
      showConfirm: false,
      clicked: false,
    };
  },

  methods: {

    setEmail(value) {
      this.user.email = value;
    },
    setPassword(value) {
      this.user.password = value;
    },
    setConfirm(value) {
      this.user.confirm = value;
    },
 
    ...mapActions({ create: "auth/signup" }),
    doSave() {
      const { email, password, confirm } = this.user;

      if (email == "" || password == "" || confirm == "") {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "All fields are required",
          uptime: 3000,
          hasError: true,
        });
      } else if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          String(email).toLowerCase()
        )
      ) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "Email does not exist",
          uptime: 3000,
          hasError: true,
        });
      } else if (
        /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9!@#$&()-`.+,/"]+$/.test(
          password
        ) == false
      ) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "Password must contain upper case characters, lower case characters and digits",
          uptime: 3000,
          hasError: true,
        });
      } else if (password != confirm) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "Passwords do not match",
          uptime: 3000,
          hasError: true,
        });
      } else {
        setTimeout(() => {
          this.clicked = false;
        }, 5000);
        if (!this.clicked) {
          this.clicked = true;
          this.create(this.user);
          this.$store.dispatch("notification/SET_NOTIFICATION", {
            msg: "Registering user ...",
            uptime: 3000,
          });
        }
      }
    },
  },
};
</script>

<style>
</style>