<template>
  <Form :heading1="'Update Password'">
    <template v-slot:details>
      <div class="md:flex md:space-x-12 block space-x-0">
        <div class="flex-1">
          <TextInput
            :label="'Password'"
            @input="setPassword"
            :types="showPassword ? 'text' : 'password'"
            :hasIcon="true"
          >
            <template v-slot:pass-icon>
              <ShowPasswordIcon
                :show-password="showPassword"
                @toogle-password="showPassword = !showPassword"
              />
            </template>
          </TextInput>

          <TextInput
            @input="setConfirm"
            :types="showConfirm ? 'text' : 'password'"
            :label="'Confirm Password'"
            :hasIcon="true"
          >
            <template v-slot:pass-icon>
              <ShowPasswordIcon
                :show-password="showConfirm"
                @toogle-password="showConfirm = !showConfirm"
              />
            </template>
          </TextInput>
        </div>
      </div>

      <Button :first-l="'Back'" :last-l="'Save'" @action="doUpdate" />
    </template>
  </Form>
</template>

<script>
import Button from "/src/components/form/Buttons.vue";
import TextInput from "/src/components/form/TextInput.vue";
import Form from "/src/components/form/Form.vue";
import ShowPasswordIcon from "/src/components/auth/ShowPasswordIcon.vue";
import { mapActions, mapGetters } from "vuex";
import Api from "@/services/Apis";

export default {
  name: "UpdateCredentials",
  components: {
    Button,
    TextInput,
    Form,
    ShowPasswordIcon,
  },
  data: () => {
    return {
      showPassword: false,
      showConfirm: false,
      user: {
        password: "",
        confirm: "",
      },
    };
  },
  computed: {
    ...mapGetters("auth", { me: "user" }),
    isMe() {
      return this.$route.params.id == this.me.id;
    },
  },
  beforeMount() {
    if (this.me.roles[0].name !== "ADMIN") {
      this.$router.push(`/update-account/${this.me.id}/user-credential`);
      this.user = this.me;
    } else this.getUser();
  },
  methods: {
    setPassword(value) {
      this.user.password = value;
    },
    setConfirm(value) {
      this.user.confirm = value;
    },
    ...mapActions({ updateUser: "auth/updateUser" }),

    async doUpdate() {
      const { password, confirm } = this.user;

      if (password == "" || confirm == "") {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "All fields are required",
          uptime: 3000,
          hasError: true,
        });
      } else if (password != confirm) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "Passwords do not match",
          uptime: 3000,
          hasError: true,
        });
      } else if (
        /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9!@#$&()-`.+,/"]+$/.test(
          password
        ) == false
      ) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "Password must contain upper case characters, lower case characters and digits",
          uptime: 3000,
          hasError: true,
        });
      } else {
        await Api.update("users/password", this.$route.params.id, {
          newPassword: password,
          confirmPassword: confirm,
        });

        this.$router.push("/");

        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "Updating password ...",
          uptime: 3000,
        });
      }
    },
  },
};
</script>

<style>
</style>