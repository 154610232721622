<template v-slot:tab>
  <div>
    <div class="md:flex md:space-x-12 block space-x-0">
      <div class="flex-1">
        <TextInput
          :label="'Meeting title'"
          :types="'select'"
          :not="true"
          :options="[
            { id: 'Cluster meeting', name: 'Cluster meeting' },
            { id: 'GACU Meeting', name: 'GACU Meeting' },
            { id: 'IMCC Meeting', name: 'IMCC Meeting' },
            { id: 'Stocktake Meeting', name: 'Stocktake Meeting' },
            { id: 'Cabinet meeting', name: 'Cabinet meeting' },
            { id: 'Parliament sessions', name: 'Parliament sessions' },
            { id: 'Other meetings', name: 'Other meetings' },
          ]"
          @input="setTitle"
        />
        <TextInput
          :label="'Short description'"
          :types="'textarea'"
          @input="setDescription"
        />
      </div>
      <div class="flex-1">
        <TextInput
          :label="'Status'"
          :types="'select'"
          :options="[
            { id: 'DRAFT', name: 'Draft' },
            { id: 'ANALYSIS_IS_DONE', name: 'Analysis is done' },
            { id: 'ON_HOLD', name: 'On hold' },
            { id: 'SENT_BACK', name: 'Sent back' },
            { id: 'RETURNED', name: 'Returned' },
            { id: 'APPROVED_IMCC', name: 'Approved IMCC' },
            { id: 'APPROVED_CABINET', name: 'Approved cabinet' },
            { id: 'APPROVED_PARLIAMENT', name: 'Approved parliament' },
            { id: 'APPROVED_CLUSTER', name: 'Approved cluster' },
            { id: 'GAZETTED', name: 'Gazetted' },
          ]"
          @input="setStatus"
          :not="true"
        />
        <TextInput :label="'Meeting Date'" :types="'date'" @input="setDate" />
      </div>
    </div>
    <Button :first-l="'Cancel'" :last-l="'Save'" @action="saveDecision" />
  </div>
</template>

<script>
import Button from "/src/components/form/Buttons.vue";
import TextInput from "/src/components/form/TextInput.vue";
import Apis from "/src/services/Apis";
import { formatDate } from "/src/functions";

export default {
  name: "DecisionTaken",
  components: {
    Button,
    TextInput,
  },
  data: () => ({
    title: "",
    clicked: false,
    description: "",
    status: "",
    date: new Date().toISOString().substr(0, 10),
  }),
  props: {
    document: Object,
  },
  methods: {
    saveDecision() {
      if (
        this.title == "" ||
        this.description == "" ||
        this.cluster == "" ||
        this.date == ""
      ) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "All fields are required",
          uptime: 3000,
          hasError: true,
        });
      } else {
        setTimeout(() => {
          this.clicked = false;
        }, 5000);
        if (!this.clicked) {
          this.clicked = true
          Apis.create("document-timelines", {
            documentId: this.$route.params.id,
            meetingDescription: this.description,
            meetingTitle: this.title,
            status: "INITIAL",
            meetingDate: this.date,
          });
          Apis.updateStatus(
            `documents/${this.$route.params.id}/change/status/${this.status}`
          );
          this.$store.dispatch("notification/SET_NOTIFICATION", {
            msg: "Decision was successfully created",
          });
          setTimeout(() => {
            this.$router.push("/documents");
          }, 4000);
        }
      }
    },
    setTitle(value) {
      this.title = value;
    },
    setDescription(value) {
      this.description = value;
    },
    setDate(value) {
      this.date = formatDate(value);
    },
    setStatus(value) {
      this.status = value;
    },
  },
};
</script>

<style></style>
