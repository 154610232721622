<template>
  <Form :heading1="'Update institution'">
    <template v-slot:details>
      <div class="md:flex md:space-x-12 block space-x-0">
        <div class="flex-1">
          <TextInput
            :label="'Name'"
            :defaultValue="institution.name"
            @input="setName"
            :types="'text'"
          />
          <TextInput
            :label="'Short description'"
            :defaultValue="institution.description"
            @input="setDescription"
            :types="'textarea'"
          />
        </div>

        <div class="flex-1">
          <TextInput
            :label="'Cluster'"
            :types="'select'"
            :defaultValue="institution.cluster.id"
            @input="setCluster"
            :options="clusters"
          />
          <TextInput
            :label="'Status'"
            :types="'select'"
            :defaultValue="institution.status"
            @input="setStatus"
            :options="[
              { id: 'ACTIVE', name: 'ACTIVE' },
              { id: 'INACTIVE', name: 'INACTIVE' },
            ]"
          />
        </div>
      </div>

      <Button :first-l="'Cancel'" :last-l="'Save'" @action="doSave" />
    </template>
  </Form>
</template>

<script>
import Button from "/src/components/form/Buttons.vue";
import TextInput from "/src/components/form/TextInput.vue";
import Form from "/src/components/form/Form.vue";
import { mapState } from "vuex";
import Apis from "/src/services/Apis";

export default {
  name: "Institution",
  data: () => ({
    clusters: [],
    showPassword: false,
    showConfirm: false,
  }),
  components: {
    Button,
    TextInput,
    Form,
  },
  computed: {
    ...mapState("admin", { institution: "selectedInstitution" }),
  },
  methods: {
    setName(value) {
      this.institution.name = value;
    },
    setDescription(value) {
      this.institution.description = value;
    },
    setStatus(value) {
      this.institution.status = value;
    },
    setCluster(value) {
      this.institution.cluster.id = value;
    },
    async doSave() {
      const { id, cluster, description, name, status } = this.institution;
      if (name == "" || description == "" || cluster.id == "" || status == "") {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "All fields are required",
          uptime: 3000,
          hasError: true,
        });
      } else {
        await Apis.update('institutions', id, { clusterId: cluster.id, name, description, status })
        .then(()=> {
          this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "Updating institution ...",
          uptime: 3000,
          });
          this.$router.push('/all-ministries')
        })
      }
    },

    async getClusters() {
      await Apis.get("clusters")
        .then((res) => (this.clusters = res.data.content))
        .catch((err) => console.log(err));
    },
  },
  created() {
    this.getClusters();
  },
};
</script>

<style>
</style>