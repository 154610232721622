var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"md:flex md:space-x-12 block space-x-0"},[_c('div',{staticClass:"flex-1"},[_c('TextInput',{attrs:{"label":'Status',"types":'select',"options":[
          { id: 'DRAFT', name: 'Draft' },
          { id: 'ANALYSIS_IS_DONE', name: 'Analysis is done' },
          { id: 'ON_HOLD', name: 'On hold' },
          { id: 'SENT_BACK', name: 'Sent back' },
          { id: 'RETURNED', name: 'Returned' },
          { id: 'APPROVED_IMCC', name: 'Approved IMCC' },
          { id: 'APPROVED_CABINET', name: 'Approved cabinet' },
          { id: 'APPROVED_PARLIAMENT', name: 'Approved parliament' },
          { id: 'APPROVED_CLUSTER', name: 'Approved cluster' },
          { id: 'GAZETTED', name: 'Gazetted' } ],"not":true},on:{"input":_vm.setStatus}}),_c('TextInput',{attrs:{"label":'Short description',"types":'textarea'},on:{"input":_vm.setDescription}})],1),_c('div',{staticClass:"flex-1"},[_c('TextInput',{attrs:{"label":'Meeting Date',"types":'date'},on:{"input":_vm.setDate}})],1)]),_c('Button',{attrs:{"first-l":'Cancel',"last-l":'Save'},on:{"action":_vm.saveDecision}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }