<template v-slot:tab>
  <div>
    <div class="md:flex md:space-x-12 block space-x-0">
      <div class="flex-1">
        <TextInput
          :label="'Short description'"
          :types="'textarea'"
          @input="setDescription"
        />
      </div>
      <div class="flex-1">
        <DragAndDrop :label="'File'" :allowZip="true" @input="setFile" />
      </div>
    </div>
    <Button :first-l="'Cancel'" :last-l="'Save'" @action="save" />
  </div>
</template>

<script>
import Button from "/src/components/form/Buttons.vue";
import TextInput from "/src/components/form/TextInput.vue";
import DragAndDrop from "/src/components/form/DragAndDrop.vue";
import Apis from "/src/services/Apis";

export default {
  name: "UploadLatest",
  components: {
    Button,
    TextInput,
    DragAndDrop,
  },
  data: () => ({
    file: null,
    clicked: false,
    description: "",
  }),
  methods: {
    setDescription(value) {
      this.description = value;
    },
    setFile(value) {
      this.file = value;
    },
    async save() {
      if (this.description == "" || !this.file) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "All fields are required",
          uptime: 3000,
          hasError: true,
        });
      } else {
        setTimeout(() => {
          this.clicked = false;
        }, 5000);
        if (!this.clicked) {
          this.clicked = true
          const formData = new FormData();
          formData.append("document", this.file);
          Apis.create("document-timelines", {
            documentId: this.$route.params.id,
            meetingDescription: this.description,
            meetingTitle: "Uploaded a newer version",
            status: "INITIAL",
          }).then((res) => {
            Apis.configurable_update(
              "document-timelines",
              `${res.data.data.id}/upload-document`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            ).then(() => {
              this.$store.dispatch("notification/SET_NOTIFICATION", {
                msg: "Document was sucessfully uploaded",
                uptime: 1000,
              });
              setTimeout(() => {
                this.$router.push(
                  `/documents/${this.$route.params.id}/timeline`
                );
              }, 1000);
            });
          });
        }
      }
    },
  },
};
</script>

<style></style>
