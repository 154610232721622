<template>
  <Form :heading1="'Update Cluster'">
    <template v-slot:details>
      <div class="md:flex md:space-x-12 block space-x-0">
        <div class="flex-1">
          <TextInput
            :label="'Name'"
            :defaultValue="cluster.name"
            @input="setName"
            :types="'text'"
          />
          <TextInput
            :label="'Short description'"
            :defaultValue="cluster.description"
            @input="setDescription"
            :types="'textarea'"
          />
        </div>

        <div class="flex-1">
          <TextInput
            :label="'Status'"
            :types="'select'"
            :defaultValue="cluster.status"
            @input="setStatus"
            :options="[
              { id: 'ACTIVE', name: 'ACTIVE' },
              { id: 'INACTIVE', name: 'INACTIVE' },
            ]"
          />
        </div>
      </div>

      <Button :first-l="'Cancel'" :last-l="'Save'" @action="doSave" />
    </template>
  </Form>
</template>

<script>
import Button from "/src/components/form/Buttons.vue";
import TextInput from "/src/components/form/TextInput.vue";
import Form from "/src/components/form/Form.vue";
import { mapState } from "vuex";
import Apis from "/src/services/Apis";

export default {
  name: "Cluster",
  data: () => {
    return {
      showPassword: false,
      showConfirm: false,
    };
  },
  components: {
    Button,
    TextInput,
    Form,
  },
  computed: {
    ...mapState("admin", { cluster: "selectedCluster" }),
  },
  methods: {
    setName(value) {
      this.cluster.name = value;
    },
    setDescription(value) {
      this.cluster.description = value;
    },
    setStatus(value) {
      this.cluster.status = value;
    },
    async doSave() {
      const { id, description, name, status } = this.cluster;
      if (name == "" || description == "" || status == "") {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "All fields are required",
          uptime: 3000,
          hasError: true,
        });
      } else {
       await Apis.update('clusters', id, { 
          name, description, status 
        })
        .then(() =>{
          this.$store.dispatch("notification/SET_NOTIFICATION", {
            msg: "Updating cluster ...",
            uptime: 3000,
          });
          this.$router.push('/all-clusters')
        })
        .catch((err) => console.log(err))
      }
    },
  },
};
</script>

<style>
</style>